import React, {useState, useEffect} from 'react';
import SingleSpotify from './SingleSpotify';
import SpPlaylist from './SpPlaylist';

const SpotifyLine = (props) => {

    const [slideHeight, setSlideHeight] = useState()
    const [ftSize, setFtsize] = useState(1)
    const [slideWidth, setSlideWidth] = useState()
    const [col, setCol] = useState(4)
    const [url, setUrl] = useState()
    const [active, setActive] = useState(true)

    useEffect(()=>{
        setUrl(`https://open.spotify.com/embed-legacy/playlist/${props.activePls}?utm_source=generator`)
    },[props.activePls])

    useEffect(()=>{
        setCol('1fr '.repeat(props.cols))
    },[props.cols])

    useEffect(()=>{
        if(props.dimentions.width < 600){
            setSlideHeight(19)
            setFtsize(2.5)
        } else if (props.dimentions.width < 1024){
            setSlideHeight(12)
            setFtsize(1.5)
        } else {
            setSlideHeight(7.813)
            setFtsize(1)
        }
    },[props.dimentions.width])

    return (
        <>
            <div className = "container-fluid col-12 col-lg-10 col-sm-12 col-md-11 pt-2 px-5" style = {{display:"grid", gridTemplateColumns:`${col}`}}>
                {
                    props.item && props.item.map((i, ind)=>(
                        <div className = "w-100 d-flex justify-content-center p-2" key = {ind} id = {i.id}
                        style = {{
                            width:`${slideWidth}vw`,
                        }}           
                        >
                            <SingleSpotify
                                i = {i}
                                positionInd = {ind}
                                cols = {props.cols}
                                setActivePls = {props.setActivePls}
                                setActiveLine = {props.setActiveLine}
                                index = {props.index}
                                dimentions = {props.dimentions}
                                ftSize = {ftSize}
                                slideHeight = {slideHeight}
                                setUrl = {setUrl}
                                chosenPlaylist = {props.chosenPlaylist}
                                setChosenPlaylist = {props.setChosenPlaylist}
                                setActive = {setActive}
                                searchParams = {props.searchParams}
                                setSearchParams = {props.setSearchParams}
                            />
                        </div>
                    ))
                }
            </div>
            {
                active && props.activePls && props.activeLine === props.index && url? 
                <SpPlaylist
                    url = {url}
                    setActive = {setActive}
                />
                : ""
            }
        </> 
    );
};

export default SpotifyLine;