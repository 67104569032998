import React, {useRef, useState, useEffect} from 'react'
import ReactDom from 'react-dom'

const ModalOverlay = (props) => {
    const modalRef = useRef()
    const [width, setWidth] = useState()
    const [dragging, setDragging] = useState(false)
    const [diffX, setDiffX] = useState(0)
    const [diffY, setDiffY] = useState(0)
    const [bottom, setBottom] = useState(0)
    const [right, setRight] = useState(0)
    const [diff2X, setDiff2X] = useState(0)
    const [diff2Y, setDiff2Y] = useState(0)

    const dragStartHandler = (e) => {
      setDiffX(e.currentTarget.getBoundingClientRect().right - e.screenX )
      setDiffY(e.currentTarget.getBoundingClientRect().bottom - e.screenY)
      setDiff2X(e.screenX-e.currentTarget.getBoundingClientRect().left)
      setDiff2Y(e.screenY-e.currentTarget.getBoundingClientRect().top)
      setDragging(true)
    }

    const draggingHandler = (e) => {
      if(dragging){
        var borderX = diff2X + diffX + 40
        var borderY = diff2Y + diffY + 20
        var right = props.dimentions.width - e.screenX - diffX
        var bottom = props.dimentions.height - e.screenY - diffY

        if(bottom > props.dimentions.height-borderY+100){
          setBottom(props.dimentions.height-borderY)
        }

        if(right <= props.dimentions.width - borderX  && right >= 0){
          setRight(right)
        }
        if( bottom <= props.dimentions.height - borderY && bottom >= 0){
          setBottom(bottom)

        }
      }
      
    }

    const dragEndHandler = () => {
      setDragging(false)
    }

    useEffect(()=>{
      if(props.dimentions.width < 600){
        setWidth(50)
      } else if (props.dimentions.width < 1024){
        setWidth(40)
      } else {
        setWidth(28.125)
      }
  },[props.dimentions.width])

    return (props.trigger) ? (
        <div className = "position-fixed m-3 bg-light border rounded-3" ref = {modalRef}
            style = {{
                width:`${width}vw`, 
                right: `${right}px`,
                bottom: `${bottom}px`,
                zIndex:"1000",
                userSelect: dragging ? "none": "initial"
            }}
            onMouseDown={dragStartHandler}
            onMouseMove={draggingHandler}
            onMouseUp={dragEndHandler}
            onMouseLeave = {dragEndHandler}
        >
          <div className = '' 
            style = {{
              width:"100%",
              height: "95%",
              backgroundColor:"transparent"
            }}
          >
            {props.content}
          </div>
        </div>
      ) : ""
    
}

const Modal = (props) => {
    return (
      <div className = "position-relative"
      >
        {ReactDom.createPortal(
          <ModalOverlay
            content = {props.content}
            trigger = {props.trigger}
            dimentions = {props.dimentions}
          />,
          document.getElementById('modal-root')
          )
        }
      </div>
    )
  }

export default Modal