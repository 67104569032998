import React, {useState, useEffect, useRef} from 'react'
import Slider from 'react-slick'
import screenfull from "screenfull"
import LeftArrow from '../icons/LeftArrow.svg'
import RightArrow from '../icons/RightArrow.svg'
import RightArrowLong from '../icons/RightArrowLong.svg'
import LeftArrowLong from '../icons/LeftArrowLong.svg'
import ShareContent from '../ShareContent'
import Default from '../covers/Default.jpg'
import './animation.css'

import axios from 'axios'

const Playlist = (props) => {

    const [playlistRef, setPlaylistRef] = useState(null)
    const [playlist, setPlaylist] = useState()
    const [plLine, setPlLine] = useState()
    const {width, height} = props.dimentions
    const [slideHeight, setSlideHeight] = useState()
    const [ftSize, setFtsize] = useState()
    const [visible, setVisible] = useState(true)
    const [slidesToShow, setSlidesToShow] = useState()
    const [titleSize, setTitleSize] = useState()
    const [loading, setLoading] = useState(true)
    const refToPl = useRef()
    const refToSlider = useRef() 
    const [isAOpen, setIsAOpen] = useState(true)



    const itemClickHandler = (item, index) => {
        localStorage.setItem('playlist_keyword', props.plYoutubeKeyword)
        localStorage.setItem("video_index", index+1)
        props.setActiveVideo(item)
        props.setVideoTrigger(true)
        props.setScrolltoSp(0)
        props.setTog(1)
      }

    useEffect(() => {
      window.addEventListener("scroll", () => {
        // let componentHeight = document.querySelector(".compA").clientHeight;
        let scrolled = window.scrollY;

        if(refToSlider && refToSlider.current ){
            if (scrolled >= refToSlider.current.offsetTop + 0.3*refToSlider.current.clientHeight || scrolled < refToSlider.current.offsetTop + 0.8*refToSlider.current.clientHeight-props.dimentions.height) {
                setIsAOpen(false);
              } else if (scrolled <= refToSlider.current.offsetTop + 0.3*refToSlider.current.clientHeight || scrolled > refToSlider.current.offsetTop + 0.8*refToSlider.current.clientHeight-props.dimentions.height) {
                setVisible(true)
                if(playlist && playlist.length && slidesToShow*plLine >= playlist.length){
                    setVisible(false)
                }
                setIsAOpen(true);
              }
        }
      });
  
      return () => {
        window.removeEventListener("scroll", () => {
        });
      };
    }, [isAOpen, playlist, props.dimentions.width]);


    useEffect(() => {
        axios.get(`https://cumarket.net/ajaxyldb.php?keyword=${props.plYoutubeKeyword}`)
            .then(request =>
                {
                    setPlaylist(request.data.items)
                    props.setActivePlaylist(request.data.items)
                    if(request.data && request.data.items && request.data.items.length){
                        if(props.openPlayer == 1){
                            itemClickHandler(request.data.items[0], 0)
                        }
                    }
                    setVisible(true)
                    if(request.data.items.length > 11){
                        if(request.data.items.length == 12){
                            setVisible(false)
                        }
                        setPlLine(2)
                    } else {
                        if(request.data.items.length <= 6){
                            setVisible(false)
                        }
                        setPlLine(1)
                    }
                    setLoading(false)
                }
            ).catch(error => 
                {   
                    console.log(error)
                    setLoading(false)
                }
                
            )
        },[props.plYoutubeKeyword])

    useEffect(()=>{
        playlist && playlist.length && playlistRef?.slickGoTo(0)
        playlist && playlist.length && refToPl.current && refToPl.current.scrollIntoView({ behavior: "smooth" })
    },[playlist])

    useEffect(()=>{
        if(width < 600){
            setSlideHeight(20)  
            setFtsize(2.3)
            setSlidesToShow(3)
            setTitleSize(3.5)
            // setSmallWindow(true)
        } else if (width < 1024){
            setSlideHeight(12)
            setFtsize(1.5)
            setSlidesToShow(4)
            setTitleSize(2.8)
            // setSmallWindow(false)
        } else {
            setSlideHeight(7.031)
            setFtsize(1)
            setSlidesToShow(6)
            setTitleSize(2.125)
            // setSmallWindow(false)
        }
    },[width, height])

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 3,
        rows: plLine,
        inisialSlide: 0,
        mobileFirst:true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 4,
                initialSlide: 0,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 3,
                initialSlide: 0, 
                infinite: false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 3, 
                initialSlide: 0,
                infinite: false,
              }
            }
          ]
      };


    return(
        <div ref = {refToPl}>
            {
                loading?                         
                <div className = "bg-primary">
                    <div className = "container text-secondary px-5 pt-5" style = {{fontSize:"3rem", fontWeight:"200"}}>
                        loading...
                    </div>
                </div>:
                playlist ? playlist.length ?
                <div className = "bg-primary pt-5 d-flex justify-content-center flex-column align-items-center"  >
                        <div className = "col-12 col-lg-9 col-sm-12 col-md-10 py-3 pb-5 px-5 px-lg-1">
                            <div className = "d-flex flex-row justify-content-between px-3 px-md-1">
                                <div className = "text-info" style = {{fontSize:`${titleSize}rem`, fontWeight:"200", overflow:"hidden"}}>{props.ytPlaylistName}</div>
                                <ShareContent
                                    dimentions = {props.dimentions}
                                    index = {1}
                                />  
                            </div>
                        </div>
                        <div className = "container-fluid d-flex flex-row justify-content-center px-5 pb-5">
                        {
                            props.dimentions.width > 768 ? plLine === 1 ? 
                            <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-center ps-lg-5 px-md-5 visible": "d-flex justify-content-center align-items-center ps-lg-5 px-md-5 hidden"} 
                                onClick={playlistRef?.slickPrev} style = {{height:"9.9vw"}}>
                                <img src = {LeftArrow} style = {{width:"4.427vw"}}/>
                            </div> :
                            <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-start ps-lg-5 px-md-5 visible" : "d-flex justify-content-center align-items-start ps-lg-5 px-md-5 hidden" }  
                                onClick={playlistRef?.slickPrev}>
                                <img src = {LeftArrowLong} style = {{height:"20vw"}}/>
                            </div> : ""
                        }
                        <div className = "col-12 col-lg-10 col-sm-12 col-md-10 ">
                            <div className = "px-lg-5" ref = {refToSlider}>
                                <Slider {...settings} ref={setPlaylistRef} slickGoTo>
                                    { playlist && playlist.length ?
                                        playlist.map((item, index) => (
                                            <div className='px-2' key={index} onClick = {()=>{itemClickHandler(item, index)}}>
                                                <div style = {{cursor:"pointer"}} >
                                                    <img src = {item && item.snippet && item.snippet.thumbnails && item.snippet.thumbnails.standard && item.snippet.thumbnails.standard.url ? item.snippet.thumbnails.standard.url :"aaaaa"}
                                                        alt = "aaaaaa" 
                                                        style = {{
                                                            objectFit: 'cover',
                                                            width:"99%", 
                                                            height: `${slideHeight}vw`
                                                        }}
                                                        onError = {({ currentTarget }) => {
                                                            currentTarget.onerror = null // prevents looping
                                                            currentTarget.src=Default
                                                          }}    
                                                    />
                                                    <div className = "text-secondary fw-light py-3 pe-2 mb-3"
                                                        title={item.snippet.title}
                                                        style = {{
                                                            fontSize: `${ftSize}rem`,
                                                            height:`${ftSize*5.5}rem`,
                                                            maxHeight:`${ftSize*5.5}rem`, 
                                                            overflow:"hidden",
                                                            WebkitLineClamp: "3",
                                                            display: "-webkit-box",
                                                            WebkitBoxOrient: "vertical",
                                                        }}
                                                    >{index+1+'. '+item.snippet.title}</div>
                                                </div>
                                            </div>
                                        )) : ''
                                    }
                                </Slider>
                            </div>
                        </div>
                        {
                            props.dimentions.width > 768 ? plLine === 1 ? 
                            <div className ={isAOpen && visible ? "d-flex justify-content-center align-items-center pe-lg-5 px-md-5 visible" : "d-flex justify-content-center align-items-center pe-lg-5 px-md-5 hidden"} onClick={playlistRef?.slickNext} style = {{height:"9.9vw"}}>
                                <img src = {RightArrow} style = {{width:"4.427vw"}}/>
                            </div> :
                            <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-start pe-lg-5 px-md-5 visible" : "d-flex justify-content-center align-items-start pe-lg-5 px-md-5 hidden"} onClick={playlistRef?.slickNext} >
                                <img src = {RightArrowLong} style = {{height:"20vw"}}/>
                            </div> : ""
                        }
                    </div>
                </div>:
                <div className = "bg-primary">
                    <div className = "container-fluid col-12 col-lg-10 col-sm-12 col-md-10 text-secondary px-5 pt-5" style = {{fontSize:"3rem", fontWeight:"200"}}>
                        Playlist is empty
                    </div>
                </div>:""
            }
        </div>
    )
}

export default Playlist