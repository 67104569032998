import React, {useState, useRef, useEffect} from 'react'
import { Navigate } from 'react-router-dom'
import YoutubePlaylists from './YoutubePlaylists'
import SpotifyPlaylists from './SpotifyPlaylists'
import Header from './Header'
import Groups from './Groups'

const Home = (props) => {

    const [group, setGroup] = useState()
    let groupKeyword = props.searchParams.get('g')
    // const visited = localStorage.getItem("visited")?localStorage.getItem("visited"):false

    useEffect(()=>{
        if(props.groups){
            let gr = props.groups.find(g => g.keyword === groupKeyword)
            setGroup(gr)
        }

    },[])

        return(
            <> 
                <Header
                    dimentions = {props.dimentions}
                    setUserInfo = {props.setUserInfo}
                />
                <Groups
                    setSearchParams = {props.setSearchParams}
                    setGroup = {setGroup}
                    groups = {props.groups}
                    dimentions = {props.dimentions}
                    searchParams = {props.searchParams}
                />
                    
                <div>
                    { 
                    group &&
                        <YoutubePlaylists 
                            setSearchParams = {props.setSearchParams}
                            searchParams = {props.searchParams}
                            group = {group}
                            dimentions = {props.dimentions}
                            setActiveVideo = {props.setActiveVideo}
                            setVideoTrigger = {props.setVideoTrigger}
                            setActivePlaylist = {props.setActivePlaylist}
                            activeVideo = {props.activeVideo}
                            activePlaylist = {props.activePlaylist}
                            // setFull = {props.setFull}
                            plYoutubeKeyword = {props.plYoutubeKeyword}
                            setPlYoutubeKeyword = {props.setPlYoutubeKeyword}
                            // full = {props.full}
                            setPlaying = {props.setPlaying}
                            setScrolltoSp = {props.setScrolltoSp}
                            setTog = {props.setTog}
                        />
                    }
                </div>
                {
                    group &&
                    <SpotifyPlaylists
                        setSearchParams = {props.setSearchParams}
                        searchParams = {props.searchParams}
                        group = {group}
                        dimentions = {props.dimentions}
                        spotifyPlId = {props.spotifyPlId}
                        setSpotifyPlId = {props.setSpotifyPlId}
                        scrollToSp = {props.scrollToSp}
                        setScrolltoSp = {props.setScrolltoSp}
                />
                }
            </>
        )
}

export default Home