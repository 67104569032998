import React, {useState, useEffect} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios'

function Login(props) {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState()
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const config = {
      headers:{
        'Content-type':'application/json',
        'Accept': 'application/json'
       }
    }


    const navigate = useNavigate()
    
    const submitHandler = (e) => {
      e.preventDefault()
      if(!emailRegex.test(email)){
        setMessage("Please, Enter Valid Email")
      } else {
        axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/jwt/create/`,
        { 'email': email, 'password': password}
      )
        .then(response => {
          localStorage.setItem('access', response.data['access'])
          if (response.data['access']){
            const config = {
                headers:{
                  // 'Content-type':'application/json',
                  'Authorization': `JWT ${localStorage.getItem('access')}`,
                  'Accept': 'application/json'
                 }
              }
  
              axios.get(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/users/me/`,
                config
              )
                .then(response => {
                  props.setUserInfo(response.data)
                  navigate('/')
                })
                .catch(message => {
                  setMessage("Something went wrong, please, try again")
                })
          }
        })
        .catch(message => {
          setMessage("Email Or Password Is Not Valid")
        })
      }
    }
  
    console.log(props.userInfo)

    const continueWithGoogle = () =>{
        
    }

    if (props.userInfo){
        return <Navigate to = '/'/>
      } else {
      return (
        <div className = "container-fluid min-vh-100 d-flex flex-column align-items-center justify-content-center"
        style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0}}>
            {message && <div className = "text-info">{message}</div>}

            <button className = "button_sign" 
              style={{
                background:"#037A9A", 
                }}
              onClick={()=>navigate('/register')}>新用户注册</button>
  
            <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
              <input className = "input" type="email" value = {email} placeholder="用户名" onChange = {(e) => {setEmail(e.target.value); setMessage('')}} />
            </div>
  
            <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
              <input className = "input" type="password" value = {password} placeholder="密码" onChange = {(e) => {setPassword(e.target.value); setMessage('')}} />
            </div>
  
            <button className = "button_sign" onClick={submitHandler}>登录</button>
            <div className = "move-to-register" 
              onClick={()=>navigate('/reset_password')}
              style={{
                color:"#00C8D5", 
                fontWeight:"300", 
                textDecoration:"underline",
                marginTop:"1.563vw",
                cursor:"pointer"}}>
            忘记密码?</div>

      </div>
      )
    }

}

export default Login;