import React from 'react'
import MultiSlider, { Progress} from 'react-multi-bar-slider'
import '../MainPage/animation.css'

const ProgressSlider = (props) => {

    const handleSlide = (newValue) => {
        if(props.played.playedSeconds > 0){
            const videoLength = props.played.playedSeconds/props.played.played
            const currentValue = videoLength*newValue/100
            props.setPlayed({...props.played, played: newValue/100, playedSeconds:currentValue})
            props.playerRef && props.playerRef.seekTo(currentValue)
        }
    }
    

    return(
        props.played ?
        <div className = {!props.playing || props.menuVisible === "visible" ? "d-flex flex-row align-items-center justify-content-center px-3 visible" : 
        "d-flex flex-row align-items-center justify-content-center px-3 hidden"}
        >
            <MultiSlider 
                backgroundColor="#bbd8e7" 
                roundedCorners
                height={6}
                onSlide = {handleSlide}>
                <Progress color="#07294c" />
                <Progress color="#07294c" progress={props.played.played*100} />
            </MultiSlider>
        </div> : ""
    )
}

export default ProgressSlider
