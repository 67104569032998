import React, {useState} from 'react';
import './AuthStyles.css'
import axios from 'axios' 


function ResetPassword(props) {
  const [infoMessage, setInfoMessage] = useState('')
  const [confirmMessage, setConfirmMessage] = useState('')
  const [email, setEmail] = useState()
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


  const submitHandler = (e) => {

    if (!emailRegex.test(email)){
      setInfoMessage("Please, Enter Valid Email")
    } else {
      e.preventDefault()
      axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/users/checkEmail/`,
        { 'email': email, 'index': 0},
      ).then(response =>{
        axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/users/reset_password/`,
          { 'email': email},
        )
          .then(response => {
            setConfirmMessage('Activation Link Is Sent To Your Email, Please, Check And Confirm')
          })
          .catch(message => {
            setInfoMessage("Something Went Wrong Please Try Again")
          })
      }
      ).catch(message =>{
        setInfoMessage(message.response && message.response.data.detail
        ? message.response.data.detail
        : message.message)
      }
    )
    }

  }


  return (
    <div className='min-vh-100 d-flex flex-column align-items-center justify-content-center'>
      {
        confirmMessage && confirmMessage.length ? 
        <div className = "text-info">{confirmMessage}</div>:
        <>
          <div className = "text-info fs-2" style={{fontWeight:"300"}}>Enter Your Email</div>
          {infoMessage && <div className = "text-info">{infoMessage}</div>} 
          <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
            <input className = "input" type="email" value = {email} placeholder="Email" onChange = {(e) => {setEmail(e.target.value); setInfoMessage("")}} />
          </div>
          <button className = "button_sign" onClick={submitHandler}>Send</button>
        </>
      }

    </div>
  )
 
}

export default ResetPassword;