import React, {useState, useEffect, useRef} from 'react'
import SpotifyIcon from '../icons/Spotify.svg'
import {useNavigate} from 'react-router-dom'
import All from '../icons/All.svg'
import RightArrow from '../icons/RightArrow.svg'
import LeftArrow from '../icons/LeftArrow.svg'
import Slider from 'react-slick'
import axios from 'axios'
import PlaylistSpotify from './PlaylistSpotify'
import Default from '../covers/Default.jpg'
import './animation.css'

const SpotifyPlaylists = (props) => {

    const [iconSize, setIconSize] = useState()
    const [spPlaylists, setSpPlaylists] = useState()
    const [slidesToShow, setSlidesToShow] = useState()
    const [ftSize, setFtsize] = useState()
    const [slideHeight, setSlideHeight] = useState()
    const [spotifyRef, setspotifyRef] = useState(null) 
    const [visible, setVisible] = useState(true)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    let spotPl_id = props.searchParams.get('sp_id')
    const [spotifyPlId, setSpotifyPlId] = useState(spotPl_id)
    const [chosenPlaylist, setChosenPlaylist] = useState()
    const refToSpotify = useRef()

    const [isAOpen, setIsAOpen] = useState(true);

    useEffect(() => {
      window.addEventListener("scroll", () => {
        // let componentHeight = document.querySelector(".compA").clientHeight;
        let scrolled = window.scrollY;
        if (scrolled >= refToSpotify.current.offsetTop + 1.8*refToSpotify.current.clientHeight || scrolled < refToSpotify.current.offsetTop - 1.8*refToSpotify.current.clientHeight) {
          setIsAOpen(false);
        } else if (scrolled <= refToSpotify.current.offsetTop + refToSpotify.current.clientHeight/4) {
            setVisible(true)
            if(spPlaylists && spPlaylists.length && slidesToShow >= spPlaylists.length){
                setVisible(false)
            }
            setIsAOpen(true);
        }
      });
  
      return () => {
        window.removeEventListener("scroll", () => {
        });
      };
    }, [isAOpen, spPlaylists, props.dimentions.width]);

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/cuPlaylist/spotifyPlaylists/${props.group.id}/${props.group.index}/`)
        .then(res => {
            setSpPlaylists(res.data)
            if (res.data && res.data.length){
                setSpotifyPlId(res.data[0].playlist_id) 
            }
            setLoading(false)
        })
        .catch(error => { 
            console.log(error)
            setLoading(false)
        })
    },[props.group])


    useEffect(()=>{
        spPlaylists && spPlaylists.length && 
        spotifyRef?.slickGoTo(0)
    },[spPlaylists])

    useEffect(()=>{
        if(props.dimentions.width < 600){
            setIconSize(2.5)
            setFtsize(2)
            setSlideHeight(19)
            setSlidesToShow(4)
        } else if (props.dimentions.width < 1024){
            setIconSize(1.7)
            setFtsize(1.5)
            setSlideHeight(12)
            setSlidesToShow(6)
        } else {
            setIconSize(1.46)
            setFtsize(1)
            setSlideHeight(7.813)
            setSlidesToShow(8)
        }
    },[props.dimentions.width])

    const settings = {
        // focusOnSelect: true,
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 4,
        rows: 1,
        initialSlide: 0,
        mobileFirst:true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 3,
                initialSlide: 0,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 2,
                initialSlide: 0,
                infinite: false,
              }
            },
            {
                breakpoint: 480,
                settings: {
                  slidesToShow: slidesToShow,
                  slidesToScroll: 2,
                  initialSlide: 0,
                  infinite: false,
                }
              }
          ]
        };


    const spotifyPlClickHandler = (item) => {
        setSpotifyPlId(item.playlist_id) 
        setChosenPlaylist(item.id)
        props.setScrolltoSp(1)
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), sp_id: item.playlist_id })
    }

    const showAllSpotify = () => {
        navigate(`spotify/${props.group.keyword}/`)
    }


    return(
        <>
            <div ref = {refToSpotify}>
                {
                    loading?                         
                    <div className = "bg-primary">
                        <div className = "container text-secondary px-5 pt-5" style = {{fontSize:"3rem", fontWeight:"200"}}>
                            loading...
                        </div>
                    </div>:
                    spPlaylists && spPlaylists.length ? 
                    <div className = 'bg-primary d-flex flex-column justify-content-center align-items-center'>
                    <div className='col-12 col-lg-9 col-sm-12 col-md-10 py-3 px-2'>
                        <div className='d-flex flex-row justify-content-between px-5 px-lg-1'>
                            <img src = {SpotifyIcon} style = {{width:`${iconSize}vw`}} alt = "" />
                            <div className = "text-info px-3 pt-1 text-center" style = {{fontSize:`${1.33*ftSize}rem`, fontWeight:"200"}}>
                                如果希望收听我们在Spotify上的完整歌单, 请 <a className = "text-white pe-auto" target="_blank" href = "https://accounts.spotify.com/en/status">在浏览器上登录您的Spotify账户</a>
                            </div>
                            <img src = {All} style = {{width:`${iconSize}vw`, cursor:"pointer"}} alt = ""  onClick = {()=>{showAllSpotify()}}/>
                        </div>
                    </div>
                    <div className = "container-fluid d-flex flex-row justify-content-center px-5">
                        {
                            props.dimentions.width > 768 ?
                            <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-center px-md-5 visible":"d-flex justify-content-center align-items-center px-md-5 hidden"} onClick={spotifyRef?.slickPrev} style = {{height:"9.9vw"}}>
                                <img src = {LeftArrow} alt = "" 
                                    style = {{
                                        width:"4.427vw", 
                                    }}/>
                            </div> : ""
                        }
                        <div className = "col-12 col-lg-10 col-sm-12 col-md-10 py-3 pb-5">
                            <div className = "px-lg-5">
                                <Slider {...settings} ref={setspotifyRef} slickGoTo>
                                    { spPlaylists && spPlaylists.length ?
                                        spPlaylists.map((item, index) => (
                                            <div className='px-1' key={index} onClick = {()=>{spotifyPlClickHandler(item)}}>
                                                <div>
                                                    <img src = {item && item.cover ? item.cover :"aaaaa"}
                                                        className = {chosenPlaylist === item.id ? "border border-dark border-4": "border border-white border-4"}
                                                        alt = "" 
                                                        onError = {({ currentTarget }) => {
                                                            currentTarget.onerror = null // prevents looping
                                                            currentTarget.src=Default
                                                        }}
                                                        style = {{
                                                            objectFit: 'cover',
                                                            width:"99%", 
                                                            height: `${slideHeight}vw`
                                                        }}/>
                                                    <div className = "text-secondary fw-light py-3 pe-2 mb-3" 
                                                        title={item.title}
                                                        style = {{
                                                            fontSize: `${ftSize}rem`,
                                                            height:`${ftSize*5}rem`,
                                                            maxHeight:`${ftSize*5}rem`, 
                                                            overflow:"hidden",
                                                            WebkitLineClamp: "3",
                                                            display: "-webkit-box",
                                                            WebkitBoxOrient: "vertical",
                                                        }}
                                                    >{item.title}</div>
                                                </div>
                                            </div>
                                        )) : ''
                                    }
                                </Slider>
                            </div>
                        </div>
                        {
                            props.dimentions.width > 768 ?
                            <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-center px-md-5 visible":"d-flex justify-content-center align-items-center px-md-5 hidden"} onClick={spotifyRef?.slickNext} style = {{height:"9.9vw"}}>
                                <img src = {RightArrow} alt = "" 
                                    style = {{
                                        width:"4.427vw",  
                                    }}/>
                            </div> : ""
                        }
                    </div>
                </div> : ""
            }
            </div>
            <PlaylistSpotify
                spotifyPlId = {spotifyPlId}
                dimentions = {props.dimentions}
                searchParams = {props.searchParams}
                scrollToSp = {props.scrollToSp}
            />
        </>
    )
}

export default SpotifyPlaylists