import React, {useState} from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import './AuthStyles.css'
import axios from 'axios'

function ResetPasswordConfirm(props) {

  const [newPassword, setNewPassword] = useState('')
  const [reNewPassword, setReNewPassword] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [confirmMessage, setConfirmMessage] = useState('')
  const navigate = useNavigate()
  const {uid, token} = useParams()

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }


  const body = {
    'uid': uid, 'token':token, 'new_password': newPassword, 're_new_password': reNewPassword
  }

  const submitHandler = () => {
    if (!newPassword || newPassword.length<8){
      setInfoMessage("Please, provide password at least 8 symbols")
    } 

    if(newPassword === reNewPassword){
      axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/users/reset_password_confirm/`, body)
      .then(response =>
        {
          setConfirmMessage('Password has been changed, go to sign in')
        }
      ).catch(message => {
          setInfoMessage(message.response && message.response.data.new_password
          ? message.response.data.new_password
          : "Something Went Wrong Please Try Again")
        })
    } else {
        setInfoMessage('Passwords do not match')
    }

  }

  if(confirmMessage && confirmMessage.length){
      return(
        <div className = "min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <div className = "text-info">{confirmMessage}</div>
          <button className = "button_sign" 
          onClick={()=>navigate('/login')}
          >Sign In</button>
    </div>
      )
    } else {
      return (
        <>
        <div className = "min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <div className = "text-info fs-2" style={{fontWeight:"300"}}>Reset Password</div>
          {infoMessage && <div className = "text-info">{infoMessage}</div>} 
          <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
            <input className = "input" type="password" value = {newPassword} placeholder="password" onChange = {(e) => setNewPassword(e.target.value)} />
          </div>

          <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
            <input className = "input" type="password" value = {reNewPassword} placeholder="confirm password" onChange = {(e) => setReNewPassword(e.target.value)} />
          </div>

          <button className = "button_sign" onClick={submitHandler}>Reset</button>
        </div>
      </>
      )
    }
}

export default ResetPasswordConfirm;