import React, {useState, useEffect} from 'react'
import ShowAllPlaylist from './ShowAllPlaylist'
import SinglePlaylist from './SinglePlaylist'


const ShowAllSingleLine = (props) => {

    const [playlist, setPlaylist] = useState()
    const [slideHeight, setSlideHeight] = useState()
    const [ftSize, setFtsize] = useState(1)
    const [slideWidth, setSlideWidth] = useState()
    const [col, setCol] = useState(4)
    const [active, setActive] = useState(true)

    useEffect(()=>{
        setCol('1fr '.repeat(props.cols))
    },[props.cols])


    useEffect(()=>{
        if(props.dimentions.width < 600){
            setSlideHeight(25)
            setSlideWidth(42)
            setFtsize(2.5)
        } else if (props.dimentions.width < 1024){
            setSlideHeight(15)
            setSlideWidth(28)
            setFtsize(1.5)
        } else {
            setSlideHeight(9.896)
            setSlideWidth(17.708)
            setFtsize(1)
        }
    },[props.dimentions.width])

    return(
        <>
                <div className = "container-fluid col-12 col-lg-10 col-sm-12 col-md-11 px-5 pt-2" style = {{display:"grid", gridTemplateColumns:`${col}`}}>
                    {
                        props.item && props.item.map((i, ind)=>(
                            <div className = "w-100 d-flex justify-content-center" key = {ind} id = {i.id}>
                                <div className = ""
                                style = {{
                                    width:`${slideWidth}vw`,
                                }}           
                                >
                                    <SinglePlaylist
                                        i = {i}
                                        cols = {props.cols}
                                        positionInd = {ind}
                                        setActivePls = {props.setActivePls}
                                        setActiveLine = {props.setActiveLine}
                                        index = {props.index}
                                        dimentions = {props.dimentions}
                                        ftSize = {ftSize}
                                        slideHeight = {slideHeight}
                                        setActive = {setActive}
                                        chosenPlaylist = {props.chosenPlaylist}
                                        setChosenPlaylist = {props.setChosenPlaylist}
                                        searchParams = {props.searchParams}
                                        setSearchParams = {props.setSearchParams}
                                        // setFull = {props.setFull}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
            {
                active && props.activePls && props.activeLine === props.index ? 
                <ShowAllPlaylist
                    ytPlaylistKeyword = {props.activePls}
                    ftSize = {ftSize}
                    lineIndex = {props.index}
                    setPlaylist = {setPlaylist}
                    playlist = {playlist}
                    dimentions = {props.dimentions}
                    setActiveVideo = {props.setActiveVideo}
                    setVideoTrigger = {props.setVideoTrigger}
                    setActivePlaylist = {props.setActivePlaylist}
                    setActive = {setActive}
                    // setFull = {props.setFull}
                /> : ""
            }
        </> 
    )
}

export default ShowAllSingleLine
