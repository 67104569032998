import React, {useState, useEffect, useRef} from 'react'
import YoutubeButton from '../icons/YoutubeButton.svg'
import SpotifyButton from '../icons/SpotifyButton.svg'
import RightArrowLong from '../icons/RightArrowLong.svg'
import LeftArrowLong from '../icons/LeftArrowLong.svg'
import Slider from 'react-slick'
import Description from './Description'
import './animation.css'


const Groups = (props) => {

    const [groupRef, setGroupRef] = useState()
    const [imageHeight, setImageHeight] = useState()
    const [iconSize, setIconSize] = useState()
    const [ftSize, setFtsize] = useState()
    const [visible, setVisible] = useState(true)
    const [slidesToShow, setSlidesToShow] = useState()
    const [isAOpen, setIsAOpen] = useState(true);
    const elementRef = useRef()

    useEffect(() => {
      window.addEventListener("scroll", () => {
        // let componentHeight = document.querySelector(".compA").clientHeight;
        let scrolled = window.scrollY;
        if(elementRef && elementRef.current){
            if (scrolled >= elementRef.current.offsetTop + elementRef.current.clientHeight/2) {
                setIsAOpen(false);
              } else if (scrolled <= elementRef.current.offsetTop+elementRef.current.clientHeight/4) {
                  setVisible(true)
                  if(props.groups && props.groups.length && slidesToShow*3 >= props.groups.length){
                      setVisible(false)
                  }
                  setIsAOpen(true);
              }
        }
      });
  
      return () => {
        window.removeEventListener("scroll", () => {
        });
      };
    }, [isAOpen, props.dimentions.width]);

    useEffect(()=>{
        if(props.dimentions.width < 600){
            setImageHeight(23)
            setFtsize(2)
            setIconSize(4)
            setSlidesToShow(2)
        } else if (props.dimentions.width < 1024){
            setImageHeight(15)
            setFtsize(1.5)
            setIconSize(3)
            setSlidesToShow(3)
        } else {
            setImageHeight(10.417)
            setFtsize(1.25)
            setIconSize(2.083)
            setSlidesToShow(4)
        }
    },[props.dimentions.width])

    useEffect(()=>{
        props.groups && props.groups.length &&
        groupRef?.slickGoTo(0)
    },[])


    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 3,
        rows: 3,
        mobileFirst: true,
        initialSlide: 0,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 3,
                infinite: false,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 2,
                initialSlide: 0,
                infinite: false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 2,
                initialSlide: 0,
                infinite: false,
                dots: true
              }
            } 
        ]
      };


    const groupClickHandler = (item) => {
        props.setGroup(item)
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), g: item.keyword})
    }

    return (
        props.groups && props.groups.length ?
        <div style = {{background:"linear-gradient(#041832, #07294c, #07294c)"}}>
            <Description
                ftSize = {ftSize}
            />
            <div className = 'container-fluid d-flex flex-row align-items-center justify-content-center px-5' ref = {elementRef}>
                {
                    props.dimentions.width > 768 ? 
                    <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-start px-md-5 visible" : "d-flex justify-content-center align-items-start px-md-5 hidden"} onClick={groupRef?.slickPrev}>
                        <img src = {LeftArrowLong} style = {{height:"25vw"}}/>
                    </div> : ""
                }
                <div className = "col-12 col-lg-10 col-sm-12 col-md-10 d-flex flex-column px-lg-5 px-5 py-5">
                    <Slider {...settings} ref={setGroupRef} slickGoTo>
                        {props.groups.map((item, index) => (
                            <div className = "w-100 px-1 py-1" key={index} 
                                onClick = {()=>{groupClickHandler(item)}}
                                >
                                <div className = "w-100 bg-white border rounded-4 d-flex flex-column p-2" style = {{cursor:"pointer"}}>
                                    <img src = {item.img_url} 
                                        className = "rounded-4"
                                        style = {{
                                            width: "100%",
                                            height:`${imageHeight}vw`, 
                                            objectFit:'cover'
                                        }}/>
                                    <div className = "d-flex flex-row justify-content-between align-items-center">
                                        <div className = "text-primary fw-bold" title={item.title} style = {{fontSize:`${ftSize}rem`, whiteSpace:"nowrap", overflow:"hidden"}}>{item.title}</div>
                                        <div className = "d-flex justify-content-between py-2">
                                            <img src = {YoutubeButton} className = "px-1" style = {{width:`${iconSize}vw`}}/>
                                            <img src = {SpotifyButton} className = "px-1" style = {{width:`${iconSize}vw`}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                {
                    props.dimentions.width > 768 ? 
                    <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-start px-md-5 visible":"d-flex justify-content-center align-items-start px-md-5 hidden"}  onClick={groupRef?.slickNext} >
                        <img src = {RightArrowLong} style = {{height:"25vw"}}/>
                    </div> : ""
                }
            </div>
        </div> : ''
    )
}

export default Groups

