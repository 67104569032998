import React, {useState, useEffect, useRef} from 'react';
import {Routes, Route} from 'react-router';
import ShowAllGroup from './mainContent/AllGroup/ShowAllGroup'
import ShowAllSpotify from './mainContent/AllGroup/ShowAllSpotify';
import Modal from './mainContent/player/Modal'
import VideoPlayer from './mainContent/player/VideoPlayer'
import Home from './mainContent/MainPage/Home'
import LandingPage from './LandingPage'
import Registration from './mainContent/auth/Registration'
import Login from './mainContent/auth/Login'
import ResetPassword from './mainContent/auth/ResetPassword'
import ResetPasswordConfirm from './mainContent/auth/ResetPasswordConfirm';
import Activate from './mainContent/auth/Activate';
import {useSearchParams, useLocation} from 'react-router-dom'
import axios from 'axios'
import queryString from 'query-string';


const Content = (props) => {
    
  const [userInfo, setUserInfo] = useState()  
  const [activeVideo, setActiveVideo] = useState()
  const [videoTrigger, setVideoTrigger] = useState(false)
  const [activePlaylist, setActivePlaylist] = useState()
  const [searchParams, setSearchParams] = useSearchParams([])
  const [playing, setPlaying] = useState(true)
  let spotPl_id = ""
  const [spotifyPlId, setSpotifyPlId] = useState(spotPl_id)
  const [groups, setGroups] = useState()
  let pl_youtube_keyword = searchParams.get('kw') || ''
  const [scrollToSp, setScrolltoSp] = useState(0)
  const [plYoutubeKeyword, setPlYoutubeKeyword] = useState(pl_youtube_keyword)
  const [tog, setTog] = useState(1)
  const [loadUser, setLoadUser] = useState(false)
  const [loadGroups, setLoadGroups] = useState(false)

  let location = useLocation();

  //对照翻译
  const chinese = (text) => {
    var key = text.replace(" ","_")
    key = key.replace("&","_")
    let map = {
      "HAPPY_HOLIDAYS":"节日音乐",
      "Georgian_Music":"格鲁吉亚音乐",
      "Latin_Music":"拉丁",
      "Easter":"复活节",
      "Functional":"实用音乐",
      "Classical":"古典音乐",
      "Country":"乡村音乐",
      "Ethno_Folk":"民族音乐",
      "Blues":"布鲁斯",
      "Jazz":"爵士音乐",
      "Dance":"舞曲",
      "R_B":"节奏布鲁斯",
      "Rock":"摇滚",
      "Pop":"流行乐",
    }
    if(typeof(map[key]) != "undefined"){
      return map[key]
    }
    return text
  }

  const load_user = () => {
    if (localStorage.getItem('access')){ 
        axios.get(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/users/me/`,
          {
            headers:{
              // 'Content-type':'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
            }
          }
        )
          .then(response => {
            setUserInfo(response.data) 
          })
          .catch(error => {
            console.log(error)
          })
      }
      setLoadUser(true)
  }

  const googleAuthenticate = (state, code) => {
    if (state && code && !localStorage.getItem('access')){
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };

      const details = {
        'state': state,
        'code': code
      };

      const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');

      axios.post(`${process.env.REACT_APP_API_URL}/memoApp/auth/o/google-oauth2/?${formBody}`,
        config
      ).then(res =>
        {
          localStorage.setItem('access', res.data.access)
          load_user()
        }
      ).catch(error =>
        {
          console.log('authenticated with error')
          console.log(error)
        }
      )
    } else {
      load_user()
    }
  }


  useEffect(()=>{
    const values = queryString.parse(location.search);
    const state = values.state ? values.state : null;
    const code = values.code ? values.code : null;

    console.log("here")
    if(state && code){
      googleAuthenticate(state, code)
    } else {
      load_user()
    }

  },[])


  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}/cuPlaylist/get_groups/`)
    .then(res => {
        //这里翻译成中文
        res.data.forEach( (item,index) => {
          item.title = chinese(item.title)
        })
        setGroups(res.data)
        setLoadGroups(true)
    })
    .catch(error => { 
        setLoadGroups(true)
        console.log(error)
    })
  },[])


  console.log(loadUser)
  console.log(loadGroups)

    return (
        <>
            <Routes>
                <Route exact path={`/`} element = {
                  loadUser && loadGroups ?
                    userInfo &&
                    groups ?
                    <Home
                        dimentions = {props.dimentions}
                        activeVideo = {activeVideo}
                        setActiveVideo = {setActiveVideo}
                        videoTrigger = {videoTrigger}
                        setVideoTrigger = {setVideoTrigger}
                        setActivePlaylist = {setActivePlaylist}
                        groups = {groups}
                        activePlaylist = {activePlaylist}
                        plYoutubeKeyword = {plYoutubeKeyword}
                        setPlYoutubeKeyword = {setPlYoutubeKeyword}
                        searchParams = {searchParams}
                        setSearchParams = {setSearchParams}
                        spotifyPlId = {spotifyPlId}
                        setSpotifyPlId = {setSpotifyPlId}
                        setPlaying = {setPlaying}
                        scrollToSp = {scrollToSp}
                        setScrolltoSp = {setScrolltoSp}
                        userInfo = {userInfo}
                        setUserInfo = {setUserInfo}
                        setTog = {setTog}
                    /> : <LandingPage userInfo = {userInfo}/> : ""
                }    
                />
                <Route path = '/group/:groupKeyword/' element = {
                    groups &&
                    <ShowAllGroup
                        dimentions = {props.dimentions}
                        setActiveVideo = {setActiveVideo}
                        setVideoTrigger = {setVideoTrigger}
                        setActivePlaylist = {setActivePlaylist}
                        groups = {groups}
                        setUserInfo = {setUserInfo}
                        // setFull = {setFull}
                    />
                }
                />
                <Route path = '/spotify/:groupKeyword/' element = {
                    groups &&
                    <ShowAllSpotify
                        dimentions = {props.dimentions}
                        groups = {groups}
                        setUserInfo = {setUserInfo}
                    />
                }
                />
                {/* <Route exact path='/'
                    element = {
                    <LandingPage
                        userInfo = {userInfo}   
                    />
                }
                /> */}
                <Route path = '/register' element = {
                        <Registration
                            userInfo = {userInfo}
                        />
                    }             
                />
                <Route path = '/login' element = {
                    <Login
                        userInfo = {userInfo}
                        setUserInfo = {setUserInfo}
                    />
                }
                />                
                
                <Route path = '/reset_password' element = {
                    <ResetPassword
                        userInfo = {userInfo}
                    />
                }
                />
                <Route path = '/password-reset/:uid/:token' element = {
                    <ResetPasswordConfirm
                        userInfo = {userInfo}
                    />
                }
                />
                <Route path = '/activate/:uid/:token' element = {
                    <Activate
                        userInfo = {userInfo}
                    />
                }
                />
            </Routes>
            <Modal 
            trigger = {videoTrigger}
            dimentions = {props.dimentions}
            content = {
                <VideoPlayer 
                    activeVideo = {activeVideo}
                    setTrigger = {setVideoTrigger}
                    playlist = {activePlaylist}
                    setActiveVideo = {setActiveVideo}
                    dimentions = {props.dimentions}
                    setPlYoutubeKeyword = {setPlYoutubeKeyword}
                    setSpotifyPlId = {setSpotifyPlId}
                    playing = {playing}
                    setPlaying = {setPlaying}
                    setScrolltoSp = {setScrolltoSp}
                    setTog = {setTog}
                    tog = {tog}
                />}
            />
          </>
    );
};

export default Content;