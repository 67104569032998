import React, { useEffect, useState } from 'react'
import Default from '../covers/Default.jpg'

const Slide = (props) => {

    const {width, height} = props.dimentions
    const [slideHeight, setSlideHeight] = useState()
    const [ftSize, setFtsize] = useState()

    useEffect(()=>{
        if(width < 600){
            setSlideHeight(25)
            setFtsize(2.5)
        } else if (width < 1024){
            setSlideHeight(15)
            setFtsize(1.5)
        } else {
            setSlideHeight(9.896)
            setFtsize(1)
        }
    },[width, height])


    return(
        <div id = {props.item.id} className = "">
            <img  src={props.item.cover && props.item.cover} alt = ""
                onError = {({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src=Default
                  }}
                className={props.chosenPlaylist === props.item.id ? 'border border-4 border-primary rounded':'border border-4 border-light rounded'}
                style={
                    {
                        objectFit: 'cover',
                        cursor: 'pointer',
                        height:`${slideHeight}vw`,
                        width:"100%",
                    }
                }>
            </img>
            <div className = "text-light fw-light pt-3" 
                title={props.item.title}
                style = {{
                    fontSize: `${ftSize}rem`,
                    height:`${ftSize*5}rem`,
                    maxHeight:`${ftSize*5}rem`, 
                    overflow:"hidden",
                    WebkitLineClamp: "3",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                }}>{props.item.title}
            </div>
        </div>
    )
}

export default Slide