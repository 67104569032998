import React, {useState} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import './AuthStyles.css'
import axios from 'axios'


const Registration = (props) =>{

    const [infoMessage, setInfoMessage] = useState()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [confirmMessage, setConfirmMessage] = useState()
    const [checked, setChecked] = useState(false)
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const numberRegex = /^\+\d{6,13}$/i;

    const location = useLocation()

    const config = {
      headers:{
        'Content-type':'application/json',
        'Accept': 'application/json'
      }
    }


    const submitHandler = (e) => {
      e.preventDefault()
      if(!firstName || firstName.length < 2 || !lastName || lastName.length < 2){
        setInfoMessage("请填写必须信息") 
      }
      else if(!password || password.length < 8){
        setInfoMessage("密码至少8个字符")
      }
      else if (!emailRegex.test(email)){
        setInfoMessage("请输入有效有效地址")
      } else if (!numberRegex.test(mobileNumber)){
        setInfoMessage("请输入有效手机号码，并在手机号码前加上国家编码")
      }
      else if (password !== confirmPassword){
        setInfoMessage('两次输入的密码不一致')
      } else {
        axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/users/checkEmail/`,
          { 'email': email, 'index': 1},
          // config
        ).then(response => {
          axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/users/`,
            {'first_name': firstName, 'last_name': lastName, 'email': email, 'mobile_number': mobileNumber, 'gives_permission': checked, 'password': password, 're_password': confirmPassword},
            // config
          )
            .then(response => {
              setConfirmMessage('激活链接已发到您邮箱，请登录您有效访问链接进行激活')
            })
            .catch(message => {
              setInfoMessage(message.response && message.response.data.password
              ? message.response.data.password
              : "出错，请在尝试")
            })
        }
      ).catch(message =>{
        setInfoMessage(message.response && message.response.data.detail
        ? message.response.data.detail
        : message.message)
      }
    )
      }
    }
  

    const continueWithGoogle = (e) => {
      e.preventDefault()
      axios.get(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}`,
        config
      ).then(res =>
        {
          window.location.replace(res.data.authorization_url)
        }
      ).catch(
         console.log('ok')
      )
    }

    if (props.userInfo){
        return <Navigate to = '/'/>
      } else 
    if(confirmMessage && confirmMessage.length) {
        return(
          <div className = "min-vh-100 d-flex flex-column align-items-center justify-content-center">
           <div className = "text-info">{confirmMessage}</div>
          </div>
        )
      } else {
        return (
          <div className = "container-fluid min-vh-100 d-flex flex-column align-items-center"
          style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0}}>
            <div className = "fs-2 text-info" style={{
              fontWeight:"700", 
              letterSpacing:"0.04rem",
              marginTop:"4.010vw",
              marginBottom:"1.615vw"}}>注册新用户</div>
            <div className = "login-form">
                {infoMessage && <div className = "text-info" style={{marginBottom:"1vw"}}>{infoMessage}</div>}
                <div className = "button_google d-flex justify-content-center align-items-center text-white" 
                  onClick = {continueWithGoogle}>
                  使用谷歌账号登录
                  <span className = "fs-lg" style = {{marginLeft:"0.5vw", fontWeight:"500", fontSize:"1.25rem"}}>G+</span>
                </div>
                <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
                  <input className = "input" 
                    type="text" value = {firstName} 
                    placeholder="名" 
                    onChange = {(e) => {setFirstName(e.target.value); setInfoMessage("")}} />
                </div>
  
                <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
                  <input className = "input" type="text" value = {lastName} placeholder="姓" onChange = {(e) => {setLastName(e.target.value); setInfoMessage("")}} />
                </div>
  
                <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
                  <input className = "input" type="email" value = {email} placeholder="电子邮件" onChange = {(e) => {setEmail(e.target.value); setInfoMessage("")}} />
                </div>

                <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
                  <input className = "input" type="text" value = {mobileNumber} placeholder="手机号码" onChange = {(e) => {setMobileNumber(e.target.value); setInfoMessage("")}} />
                </div>
  
                <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
                  <input className = "input" type="password" value = {password} placeholder="密码" onChange = {(e) => {setPassword(e.target.value); setInfoMessage("")}} />
                </div>
  
                <div className = "login-input-wrapper" style={{marginTop:"1.563vw"}}>
                  <input className = "input" type="password" value = {confirmPassword} placeholder="确认密码" onChange = {(e) => {setConfirmPassword(e.target.value); setInfoMessage("")}} />
                </div>
                <div className = "d-flex flex-row align-items-center" style={{marginTop:"1.563vw", width:"25vw"}}>
                <input type="checkbox" 
                    style={{accentColor:"#029eb0"}}
                    checked = {checked}
                    onClick={()=>setChecked(!checked)}/>
                    <div className = "text-info"
                      style = {{marginLeft:"1vw"}} 
                    >同意邮件接收验证码</div>
                </div>
                <button className = "button_sign" onClick={submitHandler}>注册</button>
  
            </div>
          </div>
        )
      }
}

export default Registration;