import React from 'react';

const Description = (props) => {
    return (
        <div className = "bg-transparent d-flex align-items-center justify-content-center pt-5 px-4">
            <div className = "container-fluid col-11 col-lg-9 col-sm-11 col-md-9 d-flex align-items-center justify-content-center border border-info rounded-4">
                <div className = "text-secondary text-center py-3 col-9" style = {{fontSize:`${props.ftSize*1.2}rem`, fontWeight:"350"}}>
                    我们是一家提供音乐(视频)予各场所（商城，商店，餐厅，酒店，酒吧等）点播的服务商<br/>
                  
                    <a href = "mailto:playlists@cugate.com" className = "text-secondary text-decoration-none" style = {{fontWeight:"500", cursor:"pointer"}}> 欢迎咨询</a> 期待与您的合作
                </div>
            </div>
        </div>
    );
};

export default Description;