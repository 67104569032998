import React, {useEffect, useRef} from 'react';
import Close from '../icons/Close.svg'

const SpPlaylist = (props) => {

    const refToSp = useRef()

    useEffect(()=>{
        refToSp.current && refToSp.current.scrollIntoView({ behavior: "smooth" })
    },[props.url])

    return (
        <div className = "bg-dark" ref = {refToSp}>
            <div className = "container-fluid col-12 col-lg-10 col-sm-12 col-md-11 d-flex justify-content-end py-2 px-5">
                <img src = {Close} alt = "" 
                    onClick = {()=>props.setActive(false)}
                    style = {{cursor:"pointer"}}/>
            </div>
            <div className = "container-fluid col-12 col-lg-10 col-sm-12 col-md-11 pt-2 px-5">
                <iframe className = "border rounded-4 border-0" src={props.url} width="100%" height="200" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>
        </div>  
    );
};

export default SpPlaylist;