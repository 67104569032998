import React, {useEffect, useState} from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import ShowAllSingleLine from './ShowAllSingleLine'
import LeftArrow from '../icons/LeftArrow.svg'

const ShowAllGroup = (props) => {    
    const {groupKeyword} = useParams()
    const [playlists, setPlaylists] = useState()
    const [cols, setCols] = useState(4)
    const [pls, setPls] = useState([])
    const [group, setGroup] = useState([])
    const navigate = useNavigate()
    const [chosenPlaylist, setChosenPlaylist] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    let a_l = parseInt(searchParams.get('l'))
    const [activeLine, setActiveLine] = useState(a_l)
    // let a_p = searchParams.get('p')
    const [activePls, setActivePls] = useState(searchParams.get('p'))


    useEffect(()=>{
        if(props.groups){
            let gr = props.groups.find(g => g.keyword === groupKeyword)
            setGroup(gr)
        }
    },[])

    useEffect(()=>{
        if(props.dimentions.width < 600){
            setCols(2)
        } else if (props.dimentions.width < 1024){
            setCols(3)
        } else {
            setCols(4)
        }
    },[props.dimentions.width])

    useEffect(()=>{
        if(group && Object.keys(group).length){
            axios.get(`${process.env.REACT_APP_API_URL}/cuPlaylist/playlists/${group.id}/${group.index}/`)
            .then(res => {
                setPlaylists(res.data)
            })
            .catch(error => { 
                console.log(error)
            })
        }
    },[group])


    useEffect(()=>{
        var arrays = [], size = cols;
        if(playlists && playlists.length > cols){
            for (let i = 0; i < playlists.length; i += size)
            arrays.push(playlists.slice(i, i + size));
        } else {                                                                                                                
            arrays.push(playlists)
        }
        setPls(arrays)
    },[playlists, cols])


    return (
        <>
            {
                group && Object.keys(group).length ?
                    <div className = "w-100 bg-dark" style = {{minHeight:"100vh"}}>
                    <div className = "w-100 d-flex flex-column align-items-center justify-content-center">
                        <div className = "container-fluid col-12 col-lg-10 col-sm-12 col-md-11 px-5 d-flex justify-content-between">
                            <img className = "px-3 ms-2" src = {LeftArrow} style = {{width:"4vw", cursor:"pointer"}} alt = "aaa" onClick = {()=>navigate(-1)}/>
                            <div className = "text-secondary py-4" style = {{fontSize:"3.229vw", fontWeight:"200"}}>{group.title.toUpperCase()}</div> : ""
                            <div style = {{visibility:"hidden"}}></div>
                        </div>
                        <div className='w-100'>
                            {
                                pls && pls.length ? 
                                pls.map((item, index)=>(
                                    <div key = {index}>
                                        <ShowAllSingleLine
                                            item = {item}
                                            index = {index}
                                            activePls = {activePls}
                                            setActivePls = {setActivePls}
                                            activeLine = {activeLine}
                                            setActiveLine = {setActiveLine}
                                            dimentions = {props.dimentions}
                                            cols = {cols}
                                            setActiveVideo = {props.setActiveVideo}
                                            setVideoTrigger = {props.setVideoTrigger}
                                            setActivePlaylist = {props.setActivePlaylist}
                                            setVideoIndex = {props.setVideoIndex}
                                            chosenPlaylist = {chosenPlaylist}
                                            setChosenPlaylist = {setChosenPlaylist}
                                            searchParams = {searchParams}
                                            setSearchParams = {setSearchParams}
                                            // setFull = {props.setFull}
                                        />
                                    </div>
                                )) : ''
                            }
                        </div>
                    </div>
                </div>:""
            }
        </>
    )
}

export default ShowAllGroup