import React, {useEffect, useState, useRef} from 'react'
import axios from 'axios'
import Default from '../covers/Default.jpg'
import Close from '../icons/Close.svg'

const ShowAllPlaylist = (props) => {

    const [cols, setCols] = useState(6)
    const [height, setHeight] = useState()
    const [col, setCol] = useState()
    const [loading, setLoading] = useState(true)
    const allPlRef = useRef()

    useEffect(()=>{
        if(props.dimentions.width < 600){
            setCols(3)
            setHeight(20)
        } else if (props.dimentions.width < 1024){
            setCols(4)
            setHeight(13)
        } else {
            setCols(6)
            setHeight(9.896)
        }
    },[props.dimentions.width])

    useEffect(()=>{
        setCol('1fr '.repeat(cols))
    },[cols])

    
    useEffect(() => {
        axios.get(`https://cumarket.net/ajaxyldb.php?keyword=${props.ytPlaylistKeyword}`)
            .then(request =>
                {
                    props.setPlaylist(request.data.items)
                    allPlRef.current && allPlRef.current.scrollIntoView({ behavior: "smooth" })
                    setLoading(false)
                }
            ).catch(error => {
                console.log(error)
                setLoading(false)
            }
            )
        },[props.ytPlaylistKeyword])


        const itemClickHandler = (item, index) => {
            props.setVideoTrigger(true)
            props.setActiveVideo(item)
            props.setActivePlaylist(props.playlist)
            localStorage.setItem('playlist_keyword', props.ytPlaylistKeyword)
            localStorage.setItem("video_index", index+1)
            // props.setFull(true)
        }

    return(
        <div className='bg-light w-100' ref = {allPlRef}>
            <div className = "container-fluid col-12 col-lg-10 col-sm-12 col-md-11 d-flex justify-content-end py-2 px-5">
                <img src = {Close} alt = "" 
                    onClick = {()=>props.setActive(false)}
                    style = {{cursor:"pointer"}}/>
            </div>
                {
                    loading?                         
                    <div className = "bg-light">
                        <div className = "container text-secondary px-5 pt-5" style = {{fontSize:"2.5rem", fontWeight:"200"}}>
                            loading...
                        </div>
                    </div>:
                    props.playlist && props.playlist.length ? 
                    <div className ='container-fluid col-12 col-lg-10 col-sm-12 col-md-11 px-5' style = {{display:"grid", gridTemplateColumns:`${col}`}}>
                        {
                            props.playlist.map((item, index)=>(
                                <div className = "w-100 d-flex justify-content-center flex-column py-2" key = {index} id = {index}
                                    onClick = {()=>{itemClickHandler(item, index)}}
        
                                >
                                    <img  src={item && item.snippet && item.snippet.thumbnails && item.snippet.thumbnails.standard && item.snippet.thumbnails.standard.url ? item.snippet.thumbnails.standard.url :"aaaaa"} alt = "aaaaaa"
                                        className='border border-4 border-light rounded'
                                        onError = {({ currentTarget }) => {
                                            currentTarget.onerror = null // prevents looping
                                            currentTarget.src=Default
                                            }}
                                        style={
                                            {
                                                objectFit: 'cover',
                                                cursor: 'pointer',
                                                height:`${height}vw`,
                                                width:"100%",
                                            }
                                        }>
                                    </img>
                                    <div className = "text-primary fw-light pt-3 pe-2"
                                        title={item.snippet.title}
                                        style = {{
                                            fontSize: `${props.ftSize}rem`,
                                            height:`${props.ftSize*5.5}rem`,
                                            maxHeight:`${props.ftSize*5.5}rem`, 
                                            whiteSpace:"wrap",
                                            overflow:"hidden",
                                            WebkitLineClamp: "3",
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                        }}>{index+1+'. '+item.snippet.title}
                                    </div>
                                </div>
                            )) 
                        }
                </div> :
                <div className = "container-fluid text-secondary col-12 col-lg-10 col-sm-12 col-md-11 d-flex justify-content-center px-5"
                    style = {{fontSize:"2.5rem", fontWeight:"200"}}>
                    Playlist is empty
                </div>
                }
        </div>
    )
}

export default ShowAllPlaylist