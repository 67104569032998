import React from 'react';
import { useNavigate, Navigate} from 'react-router-dom';
import LeftBlue1 from '../content/mainContent/icons/LeftBlue1.svg'
import LeftBlue2 from '../content/mainContent/icons/LeftBlue2.svg'
import LeftBlue3 from '../content/mainContent/icons/LeftBlue3.svg'
import RightBlue1 from '../content/mainContent/icons/RightBlue1.svg'
import RightBlue2 from '../content/mainContent/icons/RightBlue2.svg'
import RightBlue3 from '../content/mainContent/icons/RightBlue3.svg'
import YellowRectangle from '../content/mainContent/icons/YellowRectangle.svg'
import GreenRectangle from '../content/mainContent/icons/GreenRectangle.svg'
import PinkRectangle from '../content/mainContent/icons/PinkRectangle.svg'
import FaceLanding from '../content/mainContent/icons/FaceLanding.svg'

function LandingPage(props) {

    const navigate = useNavigate()


    return (
        <div className = "container-fluid d-flex flex-column" style = {{
            background:"linear-gradient(#051932, #07294C)",
            padding:0, margin:0, minHeight:"100vh"}}>
            <div className = "d-flex flex-column justify-content-between position-relative" 
                style = {{
                    height:"7.448vw",
                    marginTop:"3.214vw"
                }}>
                <div className = "text-info text-center fs-2" style = {{fontWeight:"900"}}>你可选择的</div>
                <div className = "text-white text-center fs-3" style = {{fontWeight:"700"}}>视频和音频</div>
                <div className = "text-white text-center" style = {{fontSize:"2.5rem", fontWeight:"500"}}>流媒体频道</div>
            </div>
            <div className='container-fluid d-flex align-items-center justify-content-between position-relative'
                style={{paddingLeft:0, paddingRight:0, marginTop:"-14vw"}}
            >
                <div>
                    <img src = {LeftBlue3} alt = "" 
                    style = {{
                        height:"32.3vw", 
                        width: "5vw", 
                        marginTop:"5.427vw",
                        marginRight:"0.15vw"}}/>
                    <img src = {LeftBlue2} alt = "" 
                    style = {{
                        height:"24.828vw", 
                        width: "9.5vw", 
                        marginTop:"7.6vw",
                        marginRight:"0.6vw"}}/>
                    <img src = {LeftBlue1} alt = "" 
                    style = {{
                        height:"18.924vw", 
                        width: "11.049vw", 
                        marginTop:"9.3vw",
                        marginRight:"0.68vw"}}/>
                    <img src = {PinkRectangle} alt = "" 
                    style = {{
                        height:"14.375vw", 
                        width: "13.4vw", 
                        marginTop:"10.7vw"
                        }}/>
                </div>
                <div className = "d-flex justify-content-center">
                    <img src = {YellowRectangle} alt = "" 
                    style = {{
                        height:"11.615vw", 
                        width: "16.3vw", 
                        marginTop:"11.6vw"}}/>
                    <img src = {FaceLanding}
                        className = "position-absolute"
                        style={{
                            width:"27.344vw",
                            height:"27.344vw",
                            marginTop:"1.8vw"
                        }}
                    />
                </div>
                <div>
                    <img src = {GreenRectangle} alt = "" 
                    style = {{
                        height:"14.375vw", 
                        width: "13.4vw", 
                        marginTop:"10.7vw"}}/>
                    <img src = {RightBlue1} alt = "" 
                    style = {{
                        height:"18.924vw", 
                        width: "11.049vw", 
                        marginTop:"9.3vw",
                        marginLeft:"0.68vw"}}/>
                    <img src = {RightBlue2} alt = "" 
                    style = {{
                        height:"24.828vw", 
                        width: "9.5vw", 
                        marginTop:"7.6vw",
                        marginLeft:"0.6vw"}}/>
                    <img src = {RightBlue3} alt = "" 
                    style = {{
                        height:"32.3vw", 
                        width: "5vw", 
                        marginTop:"5.427vw",
                        marginLeft:"0.15vw"}}/>
                </div>
            </div>
            <div className = "col-12 d-flex flex-column align-items-center justify-content-center position-relative">
                <div style = {{
                    color:"#FFF95B", 
                    fontStyle:"italic",
                    fontWeight:"900",
                    fontSize:"2.25rem"}}>点播</div>
                <div style = {{
                    color:"#FFF95B",
                    fontSize:"1.5rem"
                }}>我们精心挑选的视频音频歌单和类别</div>
                <button className = "text-primary text-center" style = {{
                    background:"#FFF95B",
                    border:"none",
                    width:"12.500vw",
                    height:"2.604vw",
                    borderRadius:"0.313vw",
                    marginTop:"2.604vw"
                }} onClick={()=>{
                    // localStorage.setItem('visited', true); 
                    navigate('/login')}}>开始播放</button>
                <div className = "text-white text-center" style = {{
                    fontWeight:"300",
                    marginTop:"2.604vw",
                }}>
                    如果您有原创作品
                </div>
                <div className = "text-white text-center" style = {{
                    fontWeight:"300",
                }}>
                    欢迎加入到我们的平台
                </div>
                <div className = "text-white text-center" style = {{
                    fontWeight:"300",
                    marginBottom:"7.917vw"
                }}>
                    我们恭候您的咨询
                </div>
            </div>
        </div>
    );

}

export default LandingPage;