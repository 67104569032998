import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import Slide from './Slide'
import Slider from 'react-slick'
import RightArrowLong from '../icons/RightArrowLong.svg'
import LeftArrowLong from '../icons/LeftArrowLong.svg'
import RightArrow from '../icons/RightArrow.svg'
import LeftArrow from '../icons/LeftArrow.svg'
import axios from 'axios'
import Youtube from '../icons/Youtube.svg'
import All from '../icons/All.svg'
import Playlist from './Playlist'
import './animation.css'

const YoutubePlaylists = (props) => {

    const [ytPlaylists, setYtPlaylists] = useState()
    const [sliderRef, setSliderRef] = useState(null)
    const [plsLine, setPlsLine] = useState()
    const [iconSize, setIconSize] = useState()
    const [visible, setVisible] = useState(true)
    const [slidesToShow, setSlidesToShow] = useState()
    const [loading, setLoading] = useState(true)
    const refToPlaylists = useRef()
    const refForArrows = useRef()
    let pl_name = props.searchParams.get('pl') || ''
    const [playlistName, setPlaylistName] = useState(pl_name)
    const [chosenPlaylist, setChosenPlaylist] = useState()
    const navigate = useNavigate()
    const [isAOpen, setIsAOpen] = useState(true);
    const [openPlayer, setOpenPlayer] = useState(0)

    useEffect(() => {
      window.addEventListener("scroll", () => {
        // let componentHeight = document.querySelector(".compA").clientHeight;
        let scrolled = window.scrollY;

        if(refForArrows && refForArrows.current){
            if (scrolled >= refForArrows.current.offsetTop + refForArrows.current.clientHeight/2 || scrolled < refForArrows.current.offsetTop + 0.8*refForArrows.current.clientHeight-props.dimentions.height) {
                setIsAOpen(false);
              } else if (scrolled <= refForArrows.current.offsetTop + refForArrows.current.clientHeight/2 || scrolled > refForArrows.current.offsetTop + 0.8*refForArrows.current.clientHeight-props.dimentions.height) {
                  setVisible(true)
                  if(ytPlaylists && ytPlaylists.length && slidesToShow*plsLine >= ytPlaylists.length){
                      setVisible(false)
                  }
                  setIsAOpen(true);
              }
        }
      });

      return () => {
        window.removeEventListener("scroll", () => {
        });
      };
    }, [isAOpen, ytPlaylists, props.dimentions.width]);

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/cuPlaylist/playlists/${props.group.id}/${props.group.index}/`)
        .then(res => {
            setYtPlaylists(res.data)
            setLoading(false)
            if(res.data.length <= 7){
                setPlsLine(1)
            } else {
                setPlsLine(2)
            }
        })
        .catch(error => {
            console.log(error)
            setLoading(false)
        })
    },[props.group])

    useEffect(()=>{
        ytPlaylists && ytPlaylists.length &&
        sliderRef?.slickGoTo(0)
        ytPlaylists && ytPlaylists.length && refToPlaylists.current && refToPlaylists.current.scrollIntoView({ behavior: "smooth" })
    },[ytPlaylists])


    useEffect(()=>{
        if(props.dimentions.width < 600){
            setIconSize(2.5)
            setSlidesToShow(2)
        } else if (props.dimentions.width < 1024){
            setIconSize(1.7)
            setSlidesToShow(3)
        } else {
            setIconSize(1.46)
            setSlidesToShow(4)
        }
    },[props.dimentions.width])


    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 4,
        rows: plsLine,
        initialSlide: 0,
        mobileFirst:true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 3,
                initialSlide: 0,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 2,
                initialSlide: 0,
                infinite: false,
              }
            },
            {
                breakpoint: 480,
                settings: {
                  slidesToShow: slidesToShow,
                  slidesToScroll: 2,
                  initialSlide: 0,
                  infinite: false,
                }
              }
          ]
        };


    const playlistClickHandler = (item, index) => {
        setOpenPlayer(1)
        setPlsLine(1)
        setPlaylistName(item.title)
        sliderRef?.slickGoTo(index)
        props.setPlYoutubeKeyword(item.keyword)
        setChosenPlaylist(item.id)  
        props.setScrolltoSp(0)
        props.setSearchParams({...Object.fromEntries([...props.searchParams]), kw: item.keyword, pl: item.title})
    }

    const showAllGroup = () => {
        navigate(`group/${props.group.keyword}/`)
    }

    return(
        <>
            <div ref = {refToPlaylists}>
                {
                    loading?
                    <div className = "bg-primary">
                        <div className = "container text-secondary px-5 pt-5" style = {{fontSize:"3rem", fontWeight:"200"}}>
                            loading...
                        </div>
                    </div>:
                    ytPlaylists && ytPlaylists.length ?
                    <>
                        <div className = "bg-dark py-3 d-flex justify-content-center flex-column align-items-center">
                            <div className='col-12 col-lg-9 col-sm-12 col-md-10 py-3 px-5 px-lg-1'>
                                <div className='w-100 d-flex flex-row justify-content-between align-items-center px-3'>
                                    <img src = {Youtube} style = {{width:`${iconSize}vw`}}/>
                                    <div className = "text-secondary" style = {{fontSize:"3.229vw", fontWeight:"200"}}>{props.group.title.toUpperCase()}</div>
                                    <img src = {All} style = {{width:`${iconSize}vw`, cursor:"pointer"}} onClick = {()=>{showAllGroup()}}/>
                                </div>
                            </div>
                            <div className = "container-fluid d-flex justify-content-center px-5" ref = {refForArrows} >
                                {
                                    props.dimentions.width > 768 ? plsLine === 1 ?
                                    <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-center px-md-5 visible" : "d-flex justify-content-center align-items-center px-md-5 hidden"}  onClick={sliderRef?.slickPrev} style = {{height:"9.9vw"}}>
                                        <img src = {LeftArrow} style = {{width:"4.427vw"}}/>
                                    </div> :
                                    <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-start px-md-5 visible" : "d-flex justify-content-center align-items-start px-md-5 hidden"}  onClick={sliderRef?.slickPrev}>
                                        <img src = {LeftArrowLong} style = {{height:"25vw"}}/>
                                    </div> : ""
                                }
                                <div className = "col-12 col-lg-10 col-sm-12 col-md-10">
                                    <div className = "px-lg-5">
                                        <Slider {...settings}  ref={setSliderRef} slickGoTo >
                                            {ytPlaylists && ytPlaylists.length ?
                                                ytPlaylists.map((item, index) => (
                                                <div className = "px-2" key={index}
                                                    style = {{cursor:"pointer"}}
                                                    onClick = {() => playlistClickHandler(item, index)}>
                                                    <Slide
                                                        item = {item}
                                                        index = {index}
                                                        dimentions = {props.dimentions}
                                                        chosenPlaylist = {chosenPlaylist}
                                                    />
                                                </div>
                                            )):''}
                                        </Slider>
                                    </div>
                                </div>
                                {
                                    props.dimentions.width > 768 ? plsLine === 1 ?
                                    <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-center px-md-5 visible" : "d-flex justify-content-center align-items-center px-md-5 hidden"}  onClick={sliderRef?.slickNext} style = {{height:"9.9vw"}}>
                                        <img src = {RightArrow} style = {{width:"4.427vw"}}/>
                                    </div> :
                                    <div className = {isAOpen && visible ? "d-flex justify-content-center align-items-start px-md-5 visible": "d-flex justify-content-center align-items-start px-md-5 hidden"} onClick={sliderRef?.slickNext} >
                                        <img src = {RightArrowLong} style = {{height:"25vw"}}/>
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </> :
                    <div className = "bg-primary">
                        <div className = "container-fluid col-12 col-lg-10 col-sm-12 col-md-10 text-secondary px-5 pt-5" style = {{fontSize:"3rem", fontWeight:"200"}}>
                            Playlists for this group is not added
                        </div>
                    </div>
                }
            </div>
              <Playlist
                ytPlaylistName = {playlistName}
                plYoutubeKeyword = {props.plYoutubeKeyword}
                setSearchParams = {props.setSearchParams}
                searchParams = {props.searchParams}
                dimentions = {props.dimentions}
                setActiveVideo = {props.setActiveVideo}
                setVideoTrigger = {props.setVideoTrigger}
                setActivePlaylist = {props.setActivePlaylist}
                activeVideo = {props.activeVideo}
                activePlaylist = {props.activePlaylist}
                // setFull = {props.setFull}
                // full = {props.full}
                setPlaying = {props.setPlaying}
                setScrolltoSp = {props.setScrolltoSp}
                setTog = {props.setTog}
                openPlayer = {openPlayer}
            />
        </>
    )
}

export default YoutubePlaylists
