import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import cuGate from '../icons/CuGate.svg'
import cuGateOnWhite from '../icons/CuGateOnWhite.svg'
import cuContent from '../icons/CuContext.svg'
import cuContentOnBlue from '../icons/CuContextOnBlue.svg'
import cuMarket from '../icons/CuMarket.svg'
import cuMarketOnWhite from '../icons/CuMarketOnWhite.svg'
import cuView from '../icons/CuView.svg'
import cuViewOnWhite from '../icons/CuViewOnWhite.svg'
import cuRadio from '../icons/CuRadio.svg'
import cuRadioOnWhite from '../icons/CuRadioOnWhite.svg'
import cuTrack from '../icons/CuTrack.svg'
import cuTrackOnWhite from '../icons/CuTrackOnWhite.svg'
import person from '../icons/Person.svg'
import alert from '../icons/Alert.svg'
import CuContentNew from '../icons/CuContentNew.svg'
import Cusync from '../icons/Cusync.svg'
import Dashboard from '../icons/Dashboard.svg'
import Playlists from '../icons/Playlists.svg'
import PlaylistOnWhite from '../icons/PlaylistOnWhite.svg'



const Header = (props) => {

  const [iconWidth, setIconWidth] = useState()
  const [height, setHeight] = useState()
  const [logoutOPen, setLogoutOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(()=>{
    if(props.dimentions.width < 600){
      setIconWidth(5)
      setHeight(10)
    } else if (props.dimentions.width < 1024){
      setIconWidth(3)
      setHeight(8)
    } else {
      setIconWidth(1.354)
      setHeight(4.17)
    }
},[props.dimentions.width])

const logoutHandler = () =>{
  props.setUserInfo(null)
  localStorage.removeItem('access')
  navigate('/')
  setLogoutOpen(false)
}

      return (
        <div className = "navbar-nav bg-primary px-5" style = {{height:`${height}vw`}}>
          <div className = "container-fluid d-flex flex-row align-items-center justify-content-between" style = {{height:"100%"}}>
            <div className = {props.dimentions.width > 1024 ? "" : "w-100 d-flex flex-row justify-conten-between"} style = {{height:"100%"}}>
              {/* <ul className = "w-100 navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-row justify-content-between" style = {{height:"100%"}}>
                <a href = "https://www.cugate.com/">
                  <li className = "nav-item d-flex flex-row p-3 align-items-center" style = {{height:"100%"}}>
                    <img src = {cuGate} style = {{width:`${iconWidth}vw`}}/>
                    {
                      props.dimentions.width > 1024 ? <div className = "text-white ms-3">CuGate</div> : ""
                    }
                  </li>
                </a>
                <a href = "https://www.cumarket.net/">
                  <li className = "nav-item d-flex flex-row p-3 align-items-center" style = {{height:"100%"}}>
                    <img src = {cuMarket} style = {{width:`${iconWidth}vw`}}/>
                    {
                      props.dimentions.width > 1024 ? <div className = "text-white ms-3">CuMarket</div> : ""
                    }
                  </li>
                </a>
                <a href = "http://catalog.cugate.com/">
                  <li className = "nav-item d-flex flex-row p-3 align-items-center" style = {{height:"100%"}}>
                    <img src = {cuContentOnBlue} style = {{width:`${iconWidth}vw`}}/>
                    {
                      props.dimentions.width > 1024 ? <div className = "text-white ms-3">CuContent</div> : ""
                    }
                  </li>
                </a>
                <a href = "https://www.cumarket.net/ytb/a-1-n">
                  <li className = "nav-item d-flex flex-row p-3 align-items-center" style = {{height:"100%"}}>
                    <img src = {cuView} style = {{width:`${iconWidth}vw`}}/>
                    {
                      props.dimentions.width > 1024 ? <div className = "text-white ms-3">CuView</div> : ""
                    }
                  </li>
                </a>
                <a href = "https://curadio.cugate.com/">
                  <li className = "nav-item d-flex flex-row p-3 align-items-center" style = {{height:"100%"}}>
                    <img src = {cuRadio} style = {{width:`${iconWidth}vw`}}/>
                    {
                      props.dimentions.width > 1024 ? <div className = "text-white ms-3">CuRadio</div> : ""
                    }
                  </li>
                </a>
                <a href = "https://curadio.net/">
                  <li className = "nav-item d-flex flex-row p-3 align-items-center" style = {{height:"100%"}}>
                    <img src = {cuTrack} style = {{width:`${iconWidth}vw`}}/>
                    {
                      props.dimentions.width > 1024 ? <div className = "text-white ms-3">CuTrack</div> : ""
                    }
                  </li>
                </a>
                <a href = "https://playlists.cugate.com/" style = {{textDecoration:"None"}}>
                  <li className = "nav-item d-flex flex-row p-3 bg-white align-items-center" style = {{height:"100%"}}>
                    <img src = {PlaylistOnWhite} style = {{width:`${iconWidth}vw`}}/>
                    {
                      props.dimentions.width > 1024 ? <div className = "text-primary ms-3">Playlists</div> : ""
                    }
                  </li>
                </a>
                <a href = "http://dashboard.cugate.com/">
                  <li className = "nav-item d-flex flex-row p-3 align-items-center" style = {{height:"100%"}}>
                    <img src = {Dashboard} style = {{width:`${iconWidth}vw`}}/>
                    {
                      props.dimentions.width > 1024 ? <div className = "text-white ms-3">Dashboard</div> : ""
                    }
                  </li>
                </a>
                <a href = "http://cusync.cugate.com/">
                  <li className = "nav-item d-flex flex-row p-3 align-items-center" style = {{height:"100%"}}>
                    <img src = {Cusync} style = {{width:`${iconWidth}vw`}}/>
                    {
                      props.dimentions.width > 1024 ? <div className = "text-white ms-3">CuSync</div> : ""
                    }
                  </li>
                </a>
              </ul> */}
            </div>
            {
               props.dimentions.width > 1024 ?
                <div className = "" style = {{height:"100%"}}>
                  <ul className = "navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-row" style = {{height:"100%"}}>
                    <li className = "nav-item d-flex flex-row text-center p-3 align-items-center" style = {{height:"100%"}}>
                      <a href = "http://cugate.com/our-team.php">
                        <div className = "text-secondary ms-5">
                          关于我们
                        </div>
                      </a>
                      <a href='http://cugate.com/impressum.php'>
                        <div className = "text-secondary ms-4">
                          联系方式
                        </div>
                      </a>
                      <a href = "http://cugate.com/privacy-policy.php">
                        <div className = "text-secondary ms-4">
                          隐私政策
                        </div>
                      </a>
                      <div className = "ps-5 d-flex flex-row">
                        <div className = "ms-3">
                          <img src = {alert} style = {{width:"1.250vw", height:"1.250vw"}}/>
                        </div>
                        <div className = "ms-3">
                          <img src = {person} 
                            style = {{width:"1.250vw", height:"1.250vw", cursor:"pointer"}}
                            onClick={()=>setLogoutOpen(!logoutOPen)} />
                        </div>
                      </div>
                    </li>
                  </ul>
                  {
                    logoutOPen && 
                    <div className = "position-absolue d-flex align-items-end justify-content-end">
                      <div className = "bg-white d-flex align-items-center justify-content-center" 
                        style = {{width:"7vw", height:"3vw", cursor:"pointer"}}
                        onClick={()=>{logoutHandler()}}>
                        退出
                      </div>
                    </div>
                  }
                </div> : ""
            }
          </div>
        </div>
  );
}

export default Header