import React, {useState, useEffect, useRef} from 'react'
import ShareContent from '../ShareContent'

const PlaylistSpotify = (props) => {

    const [url, setUrl] = useState()
    const refToSpotifyPl = useRef()

    useEffect(()=>{
        setUrl(`https://open.spotify.com/embed-legacy/playlist/${props.spotifyPlId}?utm_source=generator`)
        if (props.scrollToSp === 1){
            refToSpotifyPl.current.scrollIntoView({ behavior: "smooth" });         
        }
    },[props.spotifyPlId, props.scrollToSp])

    return (
        <div ref = {refToSpotifyPl}>
            {
                props.spotifyPlId && props.spotifyPlId !== null ?
                <div className = "bg-dark">
                    <div className = "w-100 container-fluid px-lg-5 py-2 d-flex justify-content-center align-items-center">
                        <div className = "col-12 col-lg-10 col-sm-12 col-md-10 py-3 pb-5 px-4">
                                <div className = "d-flex flex-row justify-content-end px-3 px-md-1 pb-4">
                                    <ShareContent
                                        dimentions = {props.dimentions}
                                        index = {2}
                                    />  
                                </div>
                            <iframe className = "border-0 rounded-4" src={url} width="100%" height="200" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                        </div>
                    </div>
                </div> :"" 
            }
        </div>
    )
}

export default PlaylistSpotify


