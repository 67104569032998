import {useState, useEffect, useRef} from 'react'
import { BrowserRouter as Router} from 'react-router-dom';
import icon from './favicon.ico';
import Content from './content/Content'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

function App() {


  useEffect(() => {
      const favicon = document.getElementById('favicon');
      favicon.setAttribute('href', icon);
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

  const dimentions =  useWindowDimensions()
  
  return (
    <Router>
      {
        <div className = "bg-primary" style = {{minHeight:"100vh"}}>
          <ReactNotifications />
          <Content
            dimentions = {dimentions}
          />
        </div>
      }
    </Router>
  );
}

export default App;

