import React, {useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import './AuthStyles.css'

function Activate(props) {

    const [infoMessage, setInfoMessage] = useState('')
    const [confirmMessage, setConfirmMessage] = useState('')
    const {uid, token} = useParams()
    const navigate = useNavigate()

    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // }

    const verify = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/users/activation/`,
          {'uid':uid, 'token':token}
        ).then(response =>
          {
            setConfirmMessage('User has been activated, You can sign in')  
          }
        ).catch(message =>
          setInfoMessage("Something went wrong, please, try again")
        )
      }

    return (
        <div>
            {
                confirmMessage && confirmMessage.length ?
                <div className = "min-vh-100 d-flex flex-column align-items-center justify-content-center">
                    <div className = "text-info">{confirmMessage}</div>
                    <button className = "button_sign" 
                    style = {{width:"7vw"}}
                    onClick={()=>navigate('/login')}
                    >Sign In</button>
                </div> :
                <div className = "min-vh-100 d-flex flex-column align-items-center justify-content-center">
                    <div className = "text-info fs-2">Activate Your Account</div>
                    <button className = "button_sign" 
                        style = {{width:"7vw", fontWeight:"400"}}
                        onClick={()=>verify()}
                    >ACTIVATE</button>
                </div>
            }
            
        </div>
    );
}

export default Activate;